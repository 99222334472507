import DetailsForm from "./Components/client/UserLogin";
import EumletLink from "./Components/Lean/Lean-api";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <main>
        <Routes>
          <Route path="/" element={<DetailsForm />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/user" element={<EumletLink />} />
          {/* <Route path="/dashboard/user" element={<Chart />} /> */}
        </Routes>
      </main>
    </Router>
  );
}

export default App;
